/* front */

.front__image--medicine {
  background: transparent url('../img/cases/Medicine.png') no-repeat center;
  background-size: contain;
}

.front__image--blockchain {
  background: transparent url('../img/cases/Blockchain.png') no-repeat center;
  background-size: contain;
}

.front__image--crm {
  background: transparent url('../img/cases/CRM.png') no-repeat center;
  background-size: contain;
}

.front__image--monitoring {
  background: transparent url('../img/cases/Monitoring.png') no-repeat center;
  background-size: contain;
}

.front__image--commerce {
  background: transparent url('../img/cases/E Commerce.png') no-repeat center;
  background-size: contain;
}

.front__image--education {
  background: transparent url('../img/cases/Education.png') no-repeat center;
  background-size: contain;
}

@media
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1200px),
only screen and (                min-resolution: 192dpi) and (min-width: 1200px),
only screen and (                min-resolution: 2dppx)  and (min-width: 1200px) {

  .front__image--medicine {
    background: transparent url('../img/cases-HQ/Medicine.svg') no-repeat center;
    background-size: contain;
  }

  .front__image--blockchain {
    background: transparent url('../img/cases-HQ/Blockchain.svg') no-repeat center;
    background-size: contain;
  }

  .front__image--crm {
    background: transparent url('../img/cases-HQ/CRM.svg') no-repeat center;
    background-size: contain;
  }

  .front__image--monitoring {
    background: transparent url('../img/cases-HQ/Monitoring.svg') no-repeat center;
    background-size: contain;
  }

  .front__image--commerce {
    background: transparent url('../img/cases-HQ/E Commerce.svg') no-repeat center;
    background-size: contain;
  }

  .front__image--education {
    background: transparent url('../img/cases-HQ/Education.svg') no-repeat center;
    background-size: contain;
  }

}


/* case-head */

.case-head {
  text-align: center;
  background: var(--blue) url('../img/page-cases/bg-case.png') no-repeat;
  background-position: top right;
  background-size: contain;
  padding: 48px 32px 115px;
}

.case-head__sup {
  font-size: 12px;
  color: var(--green);
  text-transform: uppercase;
}

.case-head__title {
  font-size: 28px;
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-weight: 600;
  color: #fff;
  margin: 0 28px;
}

.case-head__subtitle {
  color: #fff;
}

@media screen and (width >= 768px) {
  .case-head {
    padding: 80px 62px 140px;
    background-size: cover;
  }
  .case-head__sup {
    font-size: 14px;
  }
  .case-head__title {
    font-size: 36px;
    margin: 20px 28px;
  }
  .case-head__subtitle {
    font-size: 16px;
  }
}

@media screen and (width >= 1200px) {

  .case-head__sup {
    text-align: left;
    font-size: 14px;
  }
  .case-head__title {
    font-size: 48px;
    text-align: left;
    margin: 28px 0;
  }
  .case-head__subtitle {
    text-align: left;
  }
}

/* case-spec */

.case-spec {
  background-color: #fff;
  margin-top: -80px;
  padding: 32px 20px;
}

.case-spec__title {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--primary);
  margin-bottom: 16px;
}

/* .case-spec__item:nth-child(2n) {
  float: right;
} */

.case-spec__divider {
  border-bottom: 1px solid #C4CDE1;
  margin: 32px 0;
}

.spec-item__title {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--paragraph);
  padding: 0.5em 0;
}

.spec-item__time {
  font-size: 14px;
  color: var(--paragraph);
}

@media screen and (width >= 768px) {
  .case-spec {
    padding: 48px 0;
    display: flex;
    justify-content: space-between;
  }
  .case-spec__block {
    flex-grow: 1;
    flex-basis: 50%;
    display: flex;
    flex-flow: column;
    padding-left: 100px;
  }
  .case-spec__item:nth-child(even) {
    margin-bottom: 32px;
  }
  .case-spec__title {
    font-size: 20px;
  }
  .spec-item__title {
    font-size: 14px;
  }
  .spec-item__time {
    font-size: 16px;
  }
  .case-spec__divider {
    border-right: 1px solid #C4CDE1;
    margin: 0;
  }
}

@media screen and (width >= 1200px) {
  .case-spec__title {
    font-size: 24px;
  }
}


/* case-desc */

.case-desc {
  padding-top: 32px;
  /* border-bottom: 1px solid #C4CDE1; */
}

.case-desc__title {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 16px;
  color: var(--primary);
  font-weight: 500;
}

.case-desc__text {
  color: var(--paragraph);
  margin: 12px 0 32px;
}

.case-list__text {
  color: var(--paragraph);
}

@media screen and (width >= 768px) {
  .case-desc {
    padding-top: 60px;
  }
  .case-desc__title {
    font-size: 20px;
  }
  .case-desc__text {
    font-size: 16px;
  }
  .case-list__text {
    font-size: 16px;
  }
}

@media screen and (width >= 1200px) {
  .case-desc__title {
    font-size: 24px;
  }
}

@media screen and (width >= 1600px) {
  .case-desc {
    border-bottom: none;
  }
}


/* slider */


.case-desc__slider {
  margin: 48px auto;
}

.fotorama.slider-flex {
  display: flex;
  justify-content: space-between;
}

.slider__slide--static {
  width: 45%;
}

.slider__slide--static {
  width: 45%;
}

.fotorama:not(.fotorama--unobtrusive)>:not(:first-child) {
  display: block;
}

.case-slider__slide {
  height: 480px;
  padding: 0 12px;
  box-sizing: border-box;
  margin-bottom: 24px;
}

.case-slider__content {
  background: #c4c4c4;
  height: 100%;
}

.slider__controls {
  display: flex;
  justify-content: center;
  align-items: center;

}

.slider__counter {
  margin: 0 24px;
  color: var(--primary);
  font-weight: 600;
}

.slider__control {
  background: var(--green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  color: #fff;
  font-size: 33px;
}

.slider__control--left .slider__control-image {
  background: transparent url('../img/icons/arrow-left.svg') no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.slider__control--right .slider__control-image {
  background: transparent url('../img/icons/arrow-right.svg') no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.testImages__control--left .slider__control-image {
  background: transparent url('../img/icons/arrow-left.svg') no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.testImages__control--right .slider__control-image {
  background: transparent url('../img/icons/arrow-right.svg') no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.other-cases__control--left .slider__control-image {
  background: transparent url('../img/icons/arrow-left.svg') no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.other-cases__control--right .slider__control-image {
  background: transparent url('../img/icons/arrow-right.svg') no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
}



@media screen and (width >= 768px) {
  .case-desc__slider {
    width: calc(400px + 30vw);
    width: 500px;
    margin: 60px auto;
  }
  .case-slider__slide {
    height: 566px;
    width: 48%;
  }
  .slider__controls {
    justify-content: flex-end;
  }
}

@media screen and (width >= 1200px) {
  .case-slider__slide {
    height: 687px;
  }
  .case-desc__slider {
    width: 600px;
  }
}

/* case-contact */

.case-contact {
  border-top: 1px solid #C4CDE1;
  margin: 48px 0 60px;
  text-align: center;
  padding-top: 40px;
}

.case-contact__title {
  font-family: 'Montserrat', 'Arial', sans-serif;
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 16px;
}

.case-contact__call {
  color: var(--paragraph);
}

.case-contact__button {
  margin: 40px 0 60px;
  text-decoration: none;
}

@media screen and (width >= 768px) {
  .case-contact {
    margin: 70px 0 80px;
    padding-top: 40px;
  }
  .case-contact__title {
    font-size: 36px;
  }
  .case-contact__call {
    font-size: 16px;
  }
  .case-contact__button {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (width >= 1200px) {
  .case-contact {
      margin: 100px 0 130px;
      padding-top: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .case-contact__title {
    text-align: left;
    font-size: 48px;
    margin-bottom: 0;
  }
  .case-contact__button {
    flex-basis: 300px;
  }
  .case-contact__call {
    margin-right: 50px;
    text-align: left;
  }
}

@media screen and (width >= 1600px) {
  .case-contact__title {
    width: 300px;
  }
  .case-contact__button {
    margin-left: 0;
    margin-right: 0;
  }
}


/* other-cases */

.other-cases {
  padding: 50px 15px 45px;
  background-color: var(--blue);
}

.other-cases__title {
  color: #fff;
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  font-family: 'Montserrat', 'Arial', sans-serif;
  margin-bottom: 25px;
}

.other-cases__slider {
  margin: 20px 30px 0;
  padding-bottom: 32px;
}

.other-cases__slider .slider__counter {
  color: #fff;
}

.other-cases__info {
  padding-top: 25px;
  text-decoration: none;
  font-size: inherit;
  cursor: pointer;
}

.other-cases__info.slide-static {
  width: 29%;
  box-sizing: content-box;
}

.other-cases__info.slide-static:nth-child(2) {
  border-right: 1px solid rgba(255, 255, 255, .24);
  border-left: 1px solid rgba(255, 255, 255, .24);
}

.other-cases__popup {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.other-cases__popup.active {
  display: flex;
  align-items: center;
}

.other-cases__info_title {
  color: #fff;
  font-weight: 500;
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 16px;
  margin-bottom: 16px;
}

.other-cases__info_text {
  color: #C4CDE1;
}

@media screen and (width >= 768px) {
  .other-cases {
    padding: 80px 30px 85px;
  }
  .other-cases__title {
    margin-bottom: 0;
    font-size: 36px;
    float: left;
  }
  .other-cases__slider {
    float: right;
    margin: 0;
  }
  .other-cases__slider > .slider__controls {
    display: flex;
  }

  .other-cases__info {
    margin: 0;
    padding: 25px 25px 0;
    box-sizing: border-box;
  }

  .other-case__slider .swiper-slide-next {
    border-left: 1px solid rgba(255, 255, 255, .24);
  }
}

@media screen and (width >= 1200px) {
  .other-case__slider .swiper-slide-next {
    border-right: 1px solid rgba(255, 255, 255, .24);
    border-left: 1px solid rgba(255, 255, 255, .24);
  }
}

@media screen and (width >= 1600px) {
  .other-cases__info {
    height: 250px;
  }
  .other-cases__title {
    font-size: 48px;
  }
  .other-cases__info_title {
    font-size: 24px;
  }
  .other-cases__info_text {
    font-size: 16px;
  }
}