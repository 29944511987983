/* cases */

.front__image--cases {
  background: transparent url('../img/cases/Main.png') no-repeat center;
  background-size: contain;
}

@media
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1200px),
only screen and (                min-resolution: 192dpi) and (min-width: 1200px),
only screen and (                min-resolution: 2dppx)  and (min-width: 1200px) {

  .front__image--cases {
    background: transparent url('../img/cases-HQ/Main.svg') no-repeat center;
    background-size: contain;
  }

}

/* cases-list */


.cases-list {
  background-color: var(--blue);
  padding-top: 10px;
}

.cases-list__title {
  font-family: 'Montserrat', 'Arial', sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
}

.cases-list__slider {
  position: relative;
  max-width: 340px;
  margin: 20px auto -45px;
}


.cases-card__image {
  width: 100%;
  margin-top: 20px;
}
.cases-card {
  color: var(--paragraph);
  text-align: center;
  height: 647px;
  cursor: pointer;
  text-decoration: none;
}

.cases-card__wrapper {
  /* max-width: 290px; */
  margin: 0 auto;
  background-color: #fff;
  height: 100%;
}

.cases-card__text {
  padding: 15px;
}

.cases-card__name {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin: 30px 40px 55px;
  color: var(--primary);
  /* padding: 0 80px; */
}

.cases-card__text {
  text-align: left;
}

.cases-list__control--left {
  position: absolute;
  left: -7px;
  top: 50%;
  margin-top: -24px;
}

.cases-list__control--right {
  position: absolute;
  right: -7px;
  top: 50%;
  margin-top: -24px;
}

.swiper-pagination {
  margin-bottom: 24px;
}

@media screen and (width >= 768px) {
  .cases-list {
    padding-top: 60px;
  }
  /* .cases-card__wrapper {
    max-width: 340px;
  } */

  .swiper-pagination {
    display: none;
  }

  .cases-list__slider {
    max-width: initial;
    margin: 50px auto -85px;
  }

  .cases-swiper {
    overflow: initial;
  }

  .cases-card {
   max-width: 355px;
   margin-bottom: 32px;
  }
  .cases-card__name {
    text-align: left;
    margin: 30px 15px 0;
  }
  .cases-card__text {
    font-size: 16px;
  }
}

@media screen and (width >= 1200px) {
  .cases-card {
   max-width: 310px;
  }
  .cases-card:nth-child(3n -1) {
    transform: translateY(-40px);
  }
  .cases-list__title {
    padding-bottom: 30px;
  }
}
@media screen and (width >= 1600px) {
  .cases-card {
    max-width: 410px;
  }
}

/* contact-section */

.contact-section {
  background: var(--primary) url('../img/page-cases/bg-contact.png') no-repeat;
  background-position: 100% 10%;
  background-size: cover;
}

.contact-section__wrapper {
  text-align: center;
  padding-top: 120px;
  padding-bottom: 80px;
}

.contact-section__title {
  font-family: 'Montserrat', 'Arial', sans-serif;
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 16px;
}

.contact-section__call {
  color: #fff;
}

.contact-section__button {
  margin-top: 40px;
  text-decoration: none;
}

@media screen and (width >= 768px) {
  .contact-section__wrapper {
    padding-top: 110px;
  }
  .contact-section__title {
    font-size: 36px;
  }
  .contact-section__call {
    font-size: 16px;
  }
  .contact-section__button {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-section__tile {
    width: 250px;
    height: 250px;
  }
}

@media screen and (width >= 1200px) {
  .contact-section__wrapper {
      padding-top: 250px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .contact-section__title {
    text-align: left;
    font-size: 48px;
    margin-bottom: 0;
  }
  .contact-section__button {
    flex-basis: 300px;
    margin-top: 0;
  }
  .contact-section__call {
    margin-right: 50px;
    text-align: left;
  }
  .contact-section__tile {
    width: 400px;
    height: 400px;
  }
}

@media screen and (width >= 1600px) {
  .contact-section__title {
    width: 300px;
  }
  .contact-section__button {
    margin-left: 0;
    margin-right: 0;
  }
}
