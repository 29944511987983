.header-contacts {
  background-color: var(--blue);
  position: relative;
  z-index: 1000;
}

.header-contacts.index-up {
  z-index: 100000;
}

@media screen and (width >= 1200px) {
  .header-contacts {
    background: linear-gradient(to left, var(--background) 50%, var(--blue) 50%);
  }
}

/* office */

section.office {
  background-color: var(--blue);
  color:#fff;
  position: relative;
}

.office__content {
  padding: 40px 0 200px;
}

.office__title {
  position: relative;
  z-index: 1000;
}

.office__title h1 {
  font-weight:600;
  font-size:28px;
  font-family:'Montserrat', 'Arial', sans-serif;
  text-align:center;
  line-height:48px;
  margin-bottom: 15px;
}
.office-slogan {
  font-weight:500;
  font-size:16px;
  font-family:'Montserrat', 'Arial', sans-serif;
  text-align:center;
  margin-bottom:40px;
  position: relative;
  z-index: 1000;
}
.office__contacts {
  display:flex;
  flex-flow:column;
  align-items:center;
  justify-content:center;
}
.office__contact-image {
  margin-bottom:20px;
  height: 24px;
  margin-bottom: 20px;
}
.office__contact-image--phone {
  background:transparent url('../img/icons/phone.svg') center no-repeat;
}

.office__contact-image--mail {
  background:transparent url('../img/icons/mail.svg') center no-repeat;
}

.office__contact-image--map {
  background:transparent url('../img/icons/map.svg') center no-repeat;
}

.office__contact__title {
  display: none;
}

.office__contact-text {
  font-weight:500;
  font-size:16px;
  font-family:'Montserrat', 'Arial', sans-serif;
  margin-bottom: 40px;
  text-align: center;
}


.map {
  position: absolute;
  height: 300px;
  left: 20px;
  right: 20px;
  bottom: -5%;
}

#map {
  display: block;
  width: 100%;
  height: 250px;
  background: transparent url('../img/page-contacts/map.png') no-repeat center;
  background-size: cover;
}

@media screen and (width >= 768px) {
  .office__content {
    padding: 70px 0 530px;
  }
  .office__title h1 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .office-slogan {
    font-size: 20px;
    margin-bottom: 60px;
  }
  .office__contacts {
    flex-flow: row wrap;
  }
  .office__contact:first-child {
    margin-right: 150px;
  }
  .office__contact:nth-child(3) {
    width: 100%;
  }
  .office__contact-image {
    height: 32px;
  }
  .office__contact-text {
    font-size: 20px;
  }
  .map {
    bottom: -10%;
    width: 100%;
    left: 0;
    right: 0;
  }

  #map {
    width: 100%;
    height: 612px;
    background: transparent url('../img/page-contacts/map-small.png') no-repeat center;
    background-size: cover;
  }
}

@media screen and (width >= 1200px) {
  section.office {
    background: linear-gradient(to left, var(--background) 50%, var(--blue) 50%);
  }
  .office__content {
    width: 50%;
    padding: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  .office__title h1 {
    text-align: left;
    line-height: 1.75;
  }
  .office-slogan {
    text-align: left;
  }
  .office__contacts {
    flex-flow: column;
    align-items: flex-start;
  }
  .office__contact__title {
    display: block;
  }
  .office__contact:first-child {
    margin-right: 0;
  }
  .office__contact:nth-child(3) {
    width: initial;
  }
  .office__contact-image {
    background-position: left;
    margin-bottom: 0;
  }
  .office__contact__title {
    font-weight: 600;
    color: var(--green);
    text-transform: uppercase;
    font-size: 12px;
    font-size: 0.75rem;
    margin-left: 40px;
  }
  .office__contact-text {
    margin-left: 40px;
  }
  .map {
    top: 12%;
    right: 0;
    z-index: 100;
    width: auto;
    left: initial;
  }
  #map {
    width: 53vw;
    height: 70vh;
  }
}

@media screen and (width >= 1600px) {
  .office__title h1 {
    font-size: 48px;
  }
}

/* address */

section.address {
  text-align: center;
  padding: 60px 0;
  position: relative;
}

.address-c {
  display: none;
  position: absolute;
  background: transparent url('../img/page-contacts/bg-C.svg') no-repeat center;
  background-size: cover;
  width: 716px;
  height: 793px;
  bottom: -30%;
  left: 21%;
}



.map__contacts-title {
  font-family:'Montserrat', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin: 0  20px 10px;
}

.map__contacts-slogan {
  font-size: 16px;
  margin-bottom: 35px;
}

.map__contacts-addr-title, .map__contacts-mail-title {
  font-weight: 600;
  font-size: 12px;
  color: var(--green);
  margin-bottom: 8px;
  text-transform: uppercase;
}
.map__contacts-addr-text, .map__contacts-mail-text {
  font-weight: 500;
  font-size: 16px;
  font-family:'Montserrat', 'Arial', sans-serif;
  margin-bottom: 45px;
}

@media screen and (width >= 768px) {
  section.address {
    padding: 170px 0 60px;
  }
  .map__contacts {
    display: flex;
  }
  .map__heading {
    width: 50%;
    text-align: left;
    margin-right: 120px;
  }
  .map__contacts-title {
    font-size: 28px;
    margin: 0 0 15px;
  }
  .map__address {
    text-align: left;
  }
  .map__contacts-addr-text, .map__contacts-mail-text {
    font-size: 20px;
  }
  .map__contacts-mail-text {
    margin-bottom: 35px;
  }
}

@media screen and (width >= 1200px) {
  section.address {
    padding: 120px 0;
  }
  .map__heading {
    margin-right: 0;
  }
  .map__address {
    display: flex;
  }
  .map__contacts-title {
    font-size: 32px;
  }
  .map__contacts-addr-text, .map__contacts-mail-text {
    font-size: 24px;
  }
  .map__contacts-addr {
    margin-right: 45px;
  }
  .address-c {
    bottom: -40%;
    left: 25%;
    display: block;
  }
}


/* sendback */

section.sendback {
  background: var(--primary) url('../img/bg-news.png') no-repeat top left;
  background-size: cover;
  padding: 40px 0 40px;
  position: relative;
}

.sendback__title {
  font-weight: 600;
  font-size: 28px;
  color: #fff;
  text-align: center;
  margin-bottom: 32px;
}


.sendback__input {
  display: block;
  border: none;
  outline: none;
  width: 100%;
  border-bottom:  1.5px solid #4862B1;
  background: transparent;
  font-weight: 500;
  font-size: 18px;
  font-family:'Montserrat', 'Arial', sans-serif;
  color: #A4ACC6;
  transition: border 0.5s;
}

input.sendback__input {
  padding: 12px 0;
}

textarea.sendback__input {
    resize: none;
    overflow: hidden;
    min-height: 30px;
}

input::-webkit-input-placeholder {
  color: #A4ACC6;
  font-size: 14px;
}
input:-moz-placeholder { /* Firefox 18- */
  color: #A4ACC6;
  font-size: 14px;
}
input::-moz-placeholder {  /* Firefox 19+ */
  color: #A4ACC6;
  font-size: 14px;
}
input:-ms-input-placeholder {
  color: #A4ACC6;
  font-size: 14px;
}
input::placeholder {
  color: #A4ACC6;
  font-size: 14px;;
}

textarea::-webkit-input-placeholder {
  color: #A4ACC6;
  font-size: 14px;
}
textarea:-moz-placeholder { /* Firefox 18- */
  color: #A4ACC6;
  font-size: 14px;
}
textarea::-moz-placeholder {  /* Firefox 19+ */
  color: #A4ACC6;
  font-size: 14px;
}
textarea:-ms-input-placeholder {
  color: #A4ACC6;
  font-size: 14px;
}
textarea::placeholder {
  color: #A4ACC6;
  font-size: 14px;;
}

.sendback__submit {
  margin: 48px auto 0;
  width: 290px;
  text-align: center;
  height: initial;
  display: block;
  cursor: pointer;
  font-size: 16px;
}

.contact-section__arrow {
  font-size: 30px;
  margin-left: 15px;
}
@media screen and (width >= 768px) {
  section.sendback {
    padding: 60px 0 90px;
  }
  input:-moz-placeholder {
  font-size: 20px;
  }
  input::-webkit-input-placeholder {
    font-size: 20px;
  }
  textarea:-moz-placeholder {
  font-size: 20px;
  }
  textarea::-webkit-input-placeholder {
    font-size: 20px;
  }
  textarea.sendback__input {
    padding: 0;
  }
  .sendback__submit {
    width: 160px;
  }
}

@media screen and (width >= 1200px) {
  section.sendback {
    padding: 150px 0 180px;
  }
  .sendback-container {
    display: flex;
  }
  .sendback__title {
    margin-right: 250px;
    font-size: 48px;
  }
  .sendback__form {
    flex-grow: 1;
  }
  .sendback__input {
    margin: 10px 0 40px;
  }
  input::-webkit-input-placeholder {
    font-size: 24px;
  }
  input:-moz-placeholder { /* Firefox 18- */
    font-size: 24px;
  }
  input::-moz-placeholder {  /* Firefox 19+ */
    font-size: 24px;
  }
  input:-ms-input-placeholder {
    font-size: 24px;
  }
  input::placeholder {
    font-size: 24px;
  }

  textarea::-webkit-input-placeholder {
    font-size: 24px;
  }
  textarea:-moz-placeholder { /* Firefox 18- */
    font-size: 24px;
  }
  textarea::-moz-placeholder {  /* Firefox 19+ */
    font-size: 24px;
  }
  textarea:-ms-input-placeholder {
    font-size: 24px;;
  }
  textarea::placeholder {
    font-size: 24px;
  }
  .sendback__submit {
    margin: 48px 0 0;
  }
}

@media screen and (width >= 1600px) {
  .sendback__title {
    margin-right: 385px;
  }
}
