.start__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.start__title {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

.start__title-text {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: var(--primary);
  margin: 0 15px 0 0;
}

.start__description {
  color: var(--paragraph);
  font-size: 14px;
  text-align: center;
  margin-bottom: 33px;
}

.start-career__image {
  background: transparent url('../img/page-career/carerr.png') no-repeat center;
  background-size: contain;
  /* height: 230px;
  width: 100%; */
}

.start__call {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 32px auto 48px;
  cursor: pointer;
}

.start__call:hover {
  cursor: pointer;
}

.start__call--inner {
  display: none;
}

.start__call__outer {
  display: flex;
}

.start__call_logo {
  background: transparent url('../img/icons/mouse.png') no-repeat center;
  width: 28px;
  height: 38px;
  background-size: contain;
  margin-right: 16px;
}

.start__call_text {
  color: var(--green);
  text-transform: uppercase;
  font-weight: 600;
}

@media screen and (width >= 768px) {
  .start__title-text {
    font-size: 36px;
  }
  .start__description {
    font-size: 16px;
  }
  /* .start-career__image  {
    height: 440px;
  } */
  .start__call {
    margin: 32px auto 70px;
  }
}

@media screen and (width >= 1200px) {
  .start__wrapper {
    flex-direction: row;
  }
  .start__title {
    justify-content: flex-start;
  }
  .start__title-text {
    text-align: left;
  }
  .start__description {
    text-align: left;
  }
  /* .start-career__image {
    min-width: 650px;
  } */
  .start__call {
    justify-content: flex-start;
  }
  .start__call--inner {
    display: flex;
  }

  .start__call__outer {
    display: none;
  }
  /* .case {
    clear: both;
  } */
}
@media screen and (width >= 1600px) {
  /* .start-career__image {
    min-width: 850px;
    margin-top: 84px;
  } */
  .start__title-text {
    font-size: 48px;
  }
}

@media
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1200px),
only screen and (                min-resolution: 192dpi) and (min-width: 1200px),
only screen and (                min-resolution: 2dppx)  and (min-width: 1200px) {

  .start-career__image {
    background: transparent url('../img/page-career/Big-divarication.svg') no-repeat center;
  }
}

@media screen and (height <= 570px) {
  .start__title {
    margin: 10px 0;
  }
  .start__title-text {
    font-size: 20px;
  }
  .start__description {
    margin-bottom: 13px;
  }
  .start-career__image {
    margin-bottom: 10px;
  }
  .start__call {
    margin: 10px auto;
  }
}



/* career-choose */


.career-choose {
  display: flex;
  flex-flow: column;
  color: #fff;
}

.career-choose--left {
  background: #4862B1 url('../img/page-career/bg-choose-left.png') no-repeat;
  background-size: cover;
}

.career-choose--right {
  background: #3752A4 url('../img/page-career/bg-choose-right.png') no-repeat;
  background-size: 185%;
  background-position: 100% 100%;
}

.discover {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.discover__wrapper {
  padding: 56px 0 60px;
  max-width: 250px;
  text-align: center;
}

.dicover__icon {
  width: 58px;
  height: 58px;
  margin: 0 auto 30px;
}

.dicover__icon--bag {
  background: transparent url('../img/icons/portfolio.svg') no-repeat center;
  background-size: contain;
}

.dicover__icon--hat {
  background: transparent url('../img/page-career/hat.svg') no-repeat center;
  background-size: contain;
}

.discover__title {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 15px;
}

.discover__text {
  font-size: 14px;
  text-align: center;
  margin-bottom: 45px;
  min-height: 68px;
}

.discover__btn {
  border: 2px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
  text-transform: uppercase;
  width: 230px;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  color: inherit;
  padding: 14px 32px;
}

.discover__btn-arrow {
  display: inline-block;
  background: transparent url('../img/page-career/arrow.svg') center no-repeat;
  width: 16px;
  height: 14px;
  vertical-align: middle;
  padding-left: 10px;
}

@media screen and (width >= 768px) {
  .career-choose {
    flex-flow: row nowrap;
  }
  .discover {
    flex-basis: 50%;
  }
  .discover__wrapper {
    max-width: 290px;
    padding: 90px 0 80px;
  }
  .discover__text {
    font-size: 16px;
  }
}

@media screen and (width >= 1200px) {
  .discover__wrapper {
    max-width: 290px;
    padding: 157px 0 164px;
  }
  .discover__title {
    font-size: 32px;
  }
  .dicover__icon {
    width: 70px;
    height: 70px;
    }
}
