/* start section */

.front__image--start-career {
  background: transparent url('../img/page-career/start-career.png') no-repeat center;
  background-size: contain;
}

@media
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1200px),
only screen and (                min-resolution: 192dpi) and (min-width: 1200px),
only screen and (                min-resolution: 2dppx)  and (min-width: 1200px) {

  .front__image--start-career {
    background: transparent url('../img/page-career/start-career.svg') no-repeat center;
  }

}

/* careers-section */

.careers {
  margin-bottom: 50px;
}

.career-position {
  display: flex;
  flex-flow: column;
  border-top: 1px solid #C4CDE1;

}

.career-position__content {
  padding: 30px 0;
}

.career-position:last-child {
  border-bottom: 1px solid #C4CDE1;
}

.career-position__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.career-position__title {
  color: var(--primary);
  font-size: 20px;
  font-weight: 500;
  font-family: 'Montserrat', 'Arial', sans-serif;
}

.career-position__btn {
  font-size: 27px;
  color: #627080;
}

.c-content {
  display: none;
}


.c-content__table-block, .c-content__text, .c-content__list {
  margin-bottom: 32px;
}

.c-content__name {
  color: var(--primary);
  font-size: 16px;
  font-family: 'Montserrat', 'Arial', sans-serif;
  margin-bottom: 16px;
}

.c-content__table {
  color: var(--paragraph);
}

.c-content__table-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.c-content__table-desc {
  font-size: 16px;
}

.c-content__text, .c-content__list {
  font-size: 14px;
}

.c-content__list  {
  list-style-image: url('../img/page-career/ellipse.png');
  list-style-position: outside;
  padding-left: 10px;
}

.c-content__item {
  margin: 10px 0;
  padding-left: 5px;
}

@media screen and (width >= 768px) {
  .careers {
    margin-bottom: 80px;
  }
  .career-position__title {
    font-size: 28px;
  }
  .career-position__btn {
    font-size: 40px;
    color: rgba(98, 112, 128, 0.63);
  }
  .contact-section__wrapper.career-contact {
    padding-top: 60px;
  }
  .c-content__button {
    width: 300px;
  }
}

@media screen and (width >= 1200px) {
  .career-position__content {
    padding: 45px 0;
  }


  .c-content__text, .c-content__list {
    margin-bottom: 60px;
  }
  .career-position__title {
    font-size: 32px;
  }
  .career-position__btn {
    font-size: 48px;
  }

  .c-content__block--brief {
    float: right;
    margin-left: 160px;
  }

  .c-content__block--brief .c-content__table-block {
    padding-right: 20%;
  }

  .c-content__name {
    font-size: 24px;
  }
  .c-content__text {
    font-size: 16px;
  }
  .c-content__list  {
    font-size: 16px;
    padding-left: 20px;
  }
  .c-content__item {
    margin: 14px 0;
    padding-left: 12px;
  }
  .c-content__button {
    width: 230px;
  }
}















/* contact-section */

.contact-section {
  background: var(--primary) url('../img/page-career/bg-contact.png') no-repeat;
  background-position: 100% 10%;
  background-size: cover;
}

.contact-section__wrapper {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.contact-section__title {
  font-family: 'Montserrat', 'Arial', sans-serif;
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 16px;
}

.contact-section__call {
  color: #fff;
}

.contact-section__button {
  margin-top: 40px;
}

@media screen and (width >= 768px) {
  .contact-section__wrapper {
    padding-top: 110px;
  }
  .contact-section__title {
    font-size: 36px;
  }
  .contact-section__call {
    font-size: 16px;
  }
  .contact-section__button {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-section__tile {
    width: 250px;
    height: 250px;
  }
}

@media screen and (width >= 1200px) {
  .contact-section__wrapper {
      padding-top: 250px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .contact-section__title {
    text-align: left;
    font-size: 48px;
    margin-bottom: 0;
    padding-right: 20%;
  }
  .contact-section__button {
    flex-basis: 300px;
    margin-top: 0;
  }
  .contact-section__call {
    margin-right: 50px;
    text-align: left;
  }
  .contact-section__tile {
    width: 400px;
    height: 400px;
  }
}

@media screen and (width >= 1600px) {
  .contact-section__title {
    width: 300px;
  }
  .contact-section__button {
    margin-left: 0;
    margin-right: 0;
  }
}
