/* footer */
.footer {
  background-color: var(--primary);
  color: #A4ACC6;
  padding: 25px;
}

.footer__wrapper {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 0;
}

.footer__logo {
  display: none;
}

.footer__socials {
  order: 3;
}

.footer__socials_title {
  display: none;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.footer__socials_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.social-icon {
  width: 32px;
  height: 32px;
  margin-right: 15px;
}

.social-icon:last-child {
  margin-right: 0;
}

.social-icon--gh {
    background: transparent url('../img/icons/social-git.svg') no-repeat center;
    background-size: contain;
}

.social-icon--tweet {
  background: transparent url('../img/icons//social-tweet.svg') no-repeat center;
  background-size: contain;
}

.social-icon--fb {
  background: transparent url('../img/icons/social-fb.svg') no-repeat center;
  background-size: contain;
}

.footer__menu-wrapper {
  border-top: 1px solid #49598D;
  border-bottom: 1px solid #49598D;
  order: 2;
}

.footer__menu_handler {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;
  position: relative;
  cursor: pointer;
}

.footer__popup-menu {
  transition: all 0.5s;
  display: none;
  width: 100%;
}

.popup-language {
  width: 3px;
}

.footer__popup-menu.active {
  display: block;
  border-bottom: 1px solid #49598D;
}

.footer__popup-menu-item {
  padding: 10px 0;
}

.footer__popup-menu-item a {
  text-decoration: none;
  color: inherit;
}

.footer__menu {
  display: none;
}

.footer__handler_title {
  font-weight: 600;
  font-size: 16px;
}

.footer__handler_btn {
  font-size: 30px;
  line-height: 1;
}

.footer__info {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 28px;
  order: 3;
}

.footer__info_item {
  display: inline-block;
}

.footer__info_item:last-child {
  display: block;
}

.footer__menu_title {
  font-weight: 600;
}

.foooter__menu__link a, .footer__info_item a {
  color: inherit;
  text-decoration: none;
}

.footer__socials {
  order: 1;
}

@media screen and (width >= 768px) {
  .footer__wrapper {
    flex-flow: row wrap;
  }

  .footer__menu_handler {
    display: none;
  }
  .footer__menu {
    flex-grow: 1;
    display: flex;
    font-size: 16px;
    margin-right: 30px;
    justify-content: space-between;
  }
  .footer__socials {
    order: 3;
    flex-grow: 0;
  }
  .footer__info {
    clear: both;
    margin-right: 156px;
    font-size: 16px;
    order: 3;
    width: 100%;
  }
  .footer__socials_title {
    display: block;
  }
  .footer__socials_icons {
    justify-content: flex-start;
  }
  .footer__info_item {
    flex-grow: 1;
  }
}

@media screen and (width >= 1200px) {
  .footer__wrapper {
    flex-flow: row nowrap;
  }
  .footer__logo {
    display: block;
  }
  .footer__info {
    padding-top: 0;
    order: 1;
    display: block;
    margin-right: 100px;
    width: auto;
  }
  .footer__logo {
    margin-bottom: 10px;
  }
  .footer__info_item {
     margin-bottom: 10px;
     margin-right: 20px;
  }

  .footer__menu {
    order: 2;
  }
  .footer__menu_title {
    margin-bottom: 10px;
  }
}

@media screen and (width >= 1600px) {
  .footer__info {
    margin-right: 100px;
  }
  .footer__menu {
    margin-right: 120px;
  }
}