/* front */

.front__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.front__title {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

.front__title-text {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: var(--primary);
  margin: 0 15px;
}

.front__description {
  color: var(--paragraph);
  font-size: 14px;
  text-align: center;
  margin-bottom: 33px;
}

.front__image {
  width: 100%;
  height: 230px;
  margin-bottom: 30px;
}

.front__image--main {
  background: transparent url('../img/cases/Main.png') no-repeat center;
  background-size: contain;
}


.front__call {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 32px auto 48px;
  cursor: pointer;
}

.front__call--inner {
  display: none;
}

.front__call__outer {
  display: flex;
}

.front__call_logo {
  background: transparent url('../img/icons/mouse.png') no-repeat center;
  width: 28px;
  height: 38px;
  background-size: contain;
  margin-right: 16px;
}

.front__call_text {
  color: var(--green);
  text-transform: uppercase;
  font-weight: 600;
}

.front__btn-down {
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border: 2px solid #C4CDE1;
  border-radius: 50%;
  margin-bottom: 25px;
  background: var(--background) url('../img/icons/down-arrow-mob.png') no-repeat center;
}

.front-bg {
  position: absolute;
  width: 250px;
  height: 250px;
  z-index: 100000;
  top: 0;
}

.front-bg--left {
  background: transparent url('../img/tiles/tile-left.png') no-repeat center;
  background-size: contain;
  left: 0;
}
.front-bg--right {
  display: none;
  background: transparent url('../img/tiles/tile-right.png') no-repeat center;
  background-size: contain;
  right: 0;
}

@media screen and (width >= 768px) {
  .front-bg--right {
    display: block;
  }
  .front__title-text {
    font-size: 36px;
  }
  .front__description {
    font-size: 16px;
  }
  .front__image  {
    height: 440px;
  }
  .front__call {
    margin: 32px auto 70px;
  }
}

@media screen and (width >= 1200px) {
  .front__wrapper {
    flex-direction: row;
  }
  .front__title {
    justify-content: flex-start;
  }
  .front__title-text {
    text-align: left;
    margin-left: -1px;
  }
  .front__description {
    text-align: left;
  }
  .front__image {
    min-width: 650px;
  }
  .front__call {
    justify-content: flex-start;
  }
  .front__call--inner {
    display: flex;
  }

  .front__call__outer {
    display: none;
  }
}
@media screen and (width >= 1600px) {
  section.testimonials {
    margin-bottom: -1px;
  }
  .front__title-text {
    font-size: 48px;
  }
  .front__image {
    min-width: 850px;
  }
}


@media
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1200px),
only screen and (                min-resolution: 192dpi) and (min-width: 1200px),
only screen and (                min-resolution: 2dppx)  and (min-width: 1200px) {

  .front__image--main {
    background: transparent url('../img/cases-HQ/Main.svg') no-repeat center;
    background-size: contain;
  }

}

/* iphone5 */

@media screen and (height <= 570px) {
  .front__title {
    margin: 10px 0;
  }
  .front__title-text {
    font-size: 20px;
  }
  .front__description {
    margin-bottom: 13px;
  }
  .front__image {
    margin-bottom: 10px;
  }
  .front__call {
    margin: 10px auto;
  }
}


/* main */


section.main {
  background: var(--blue);
  color: #fff;
  padding: 35px 0 10px;
  position: relative;
}

.main__title {
  font-weight: 600;
  font-size: 28px;
  font-family: 'Montserrat', 'Arial', sans-serif;
  text-align: center;
  margin-bottom: 35px;
  line-height: 1.3;
  padding-top: 1em;
}

.main__desc {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (width >= 768px) {
  .main__title {
    font-size: 36px;
  }
  .main__desc {
    font-size: 20px;
  }
}

@media screen and (width >= 1200px) {
  section.main {
    padding-top: 160px;
  }
  .main__title {
    font-size: 48px;
  }
  .main__desc {
    font-size: 24px;
    margin-bottom: 100px;
    padding: 0 10%;
  }
}

/* brands */

.main__brands__wrapper {
  margin-left: -15px;
  margin-right: -15px;
}

.main__brands {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  width: 320px;
  margin-bottom: 50px;
  justify-content: center;
}

.main-brand__item {
  flex-basis: 106px;
  flex-flow: column;
  height: 110px;
}

.main-brand__image {
  text-align: center;
  height: 65%;
  position: relative;
}

.main-brand__image img {
  display: block;
  max-width: 40px;
  width: auto;
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  margin: auto;
}

.main-brand__name {
  opacity: 0.5;
  font-size: 8px;
  height: 35%;
  text-align: center;
  line-height: 1.3;
}

.main-brand__item:nth-of-type(8n+7),
.main-brand__item:nth-of-type(8n+5),
.main-brand__item:nth-of-type(8n+3),
.main-brand__item:nth-of-type(8n+1){
 background-color: rgba(255, 255, 255, 0.06);
}

@media screen and (width >= 768px) {
  .main__brands {
    width: 708px;
  }
  .main-brand__item {
    flex-basis: 177px;
    height: 180px;
  }
  .main-brand__image img {
    max-width: 120px;
  }
  .main-brand__name {
    font-size: 16px;
  }
  .main-brand__item:nth-of-type(8n+8),
  .main-brand__item:nth-of-type(8n+6),
  .main-brand__item:nth-of-type(8n+3),
  .main-brand__item:nth-of-type(8n+1){
    background-color: rgba(255, 255, 255, 0.06);
  }
  .main-brand__item:nth-of-type(8n+5),
  .main-brand__item:nth-of-type(8n+7) {
     background-color: initial;
  }
}


@media screen and (width >= 1200px) {
  .main__brands {
    margin-bottom: 130px;
  }
  .main__title {
    margin-bottom: 50px;
  }
  .main__brands {
    width: 1000px;
  }
  .main-brand__item {
    flex-basis: 166px;
  }
  .main-brand__item:nth-of-type(12n+17),
  .main-brand__item:nth-of-type(12n+15),
  .main-brand__item:nth-of-type(12n+13),
  .main-brand__item:nth-of-type(12n+12),
  .main-brand__item:nth-of-type(12n+10),
  .main-brand__item:nth-of-type(12n+8),
  .main-brand__item:nth-of-type(12n+5),
  .main-brand__item:nth-of-type(12n+3),
  .main-brand__item:nth-of-type(12n+1) {
    background-color: rgba(255, 255, 255, 0.06);
  }
   .main-brand__item:nth-of-type(12n+9),
  .main-brand__item:nth-of-type(12n+11),
  .main-brand__item:nth-of-type(12n+2),
  .main-brand__item:nth-of-type(12n+4),
  .main-brand__item:nth-of-type(2),
  .main-brand__item:nth-of-type(12n+6) {
     background-color: initial;
  }
}

@media screen and (width >= 1600px) {
  .main__brands {
    width: 1290px;
  }
  .main-brand__item {
    flex-basis: 215px;
  }
}

/* experience */

 .experience-list__slider {
  margin: 20px auto -45px;
  position: relative;
}

@media screen and (width >= 768px) {
  .experience-list__slider {
      margin: 20px auto -130px;
  }
}

/* testimonials */

.testimonials {
  padding-top: 100px;
  position: relative;
}

.testimonials__6 {
  display: none;
  background: transparent url('../img/page-index/bg-6.svg') no-repeat  center;
  background-size: contain;
  position: absolute;
  width: 568.99px;
  height: 1062.23px;
  top: -15%;
  right: 0;
}

.testimonials__title {
  color: var(--primary);
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  font-family: 'Montserrat', 'Arial', sans-serif;
}

.testimonials__slider {
  margin: 15px 30px 0;
  padding-bottom: 60px;
}

.testimonials__slider .slider__controls {
  margin-bottom: 25px;
}

.testimonials__copy {
  margin: 0 auto 24px;
  width: 42px;
  height: 32px;
  background: transparent url('../img/icons/copy.svg') no-repeat left center;
  background-size: contain;
}

.testimonials__details {
  display: flex;
  flex-flow: column;
  /* clear: both; */
  margin-bottom: 35px;
  justify-content: space-around;
  border-top: 1px solid #C4CDE1;
  border-bottom: 1px solid #C4CDE1;
}

.details__detail {
  padding: 15px 0;
  flex-grow: 1;
  text-align: center;
  /* width: 33%; */
  font-size: 14px;
  /* border-right: 1px solid #C4CDE1; */
  border-bottom: 1px solid #C4CDE1;
}

.details__detail:last-child {
  border-right: none;
}

.details__title {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
}


.testimonials__list {
  display: flex;
  flex-flow: column nowrap;
}

.testimonial__text {
  margin-bottom: 15px;
}

.testimonial__author {
  text-align: center;
  margin: 25px 0 45px;
}

.author__role {
  margin: 10px 0;
}

.author__name {
  font-weight: 600;
  color: var(--primary);
  text-transform: uppercase;
}

@media screen and (width >= 768px) {
  .testimonials {
    padding-top: 160px;
  }

  .testimonials__slider-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .testimonials__title {
    width: 100%;
    margin-bottom: 36px;
  }

  .cases__controls {
    margin: 0;
  }

  .testimonials__details {
   flex-flow: row;
  }

  .details__detail {
    padding: 15px 0;
    flex-grow: 1;
    text-align: center;
    width: 33%;
    font-size: 14px;
    border-right: 1px solid #C4CDE1;
    border-bottom: none;
  }

  .testimonials__controls {
    overflow: hidden;
  }
  .testimonials__copy {
    margin: 0;
  }
  .testimonials__slider {
    margin: 0;
  }
  .testimonials__slider .slider__controls {
    display: flex;
  }
  .testimonial__text {
    font-size: 16px;
  }
}

@media screen and (width >= 1200px) {
  .testimonials__6 {
    display: block;
  }
}



/* testimonials__images */

.testimonials__images {
  background: var(--primary) url('../img/bg-news.png') no-repeat top left;
  background-size: 200%;
  padding: 40px 0 70px;
}

.testimonials__title--white {
  margin-bottom: 20px;
  color: #fff;
}

.images-slider {
  overflow: hidden;
}

.images-slider .slider__controls {
  margin-bottom: 32px;
}

.images-slider .slider__counter {
  color: #fff;
}

.images-slider__image {
  margin-bottom: 20px;
  position: relative;
}

.images-slider__image:before {
  content: '';
  display: block;
  padding-top: 50%;
}

.images-slider__image img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.images-slider__text {
  color: #fff;
  padding-right: 15px;
}

.testimonial__author {
  text-align: center;
  margin-bottom: 45px;
}

.author__role {
  margin: 10px 0;
}

.author__name {
  font-weight: 600;
  color: var(--primary);
  text-transform: uppercase;
}

.author__link {
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px dashed #8AC249;
  line-height: 14px;
  color: var(--paragraph);

}
.testimonials__images .testimonials__slider .slider__counter {
  color: #fff;
}


@media screen and (width >= 768px) {
  .testimonials__images {
    background-size: contain;
  }
}

/* carousel */


@media screen and (width >= 768px) {
  .news-head {
    display: flex;
    justify-content: space-between;
  }

  .testimonials__title.testimonials__title--white {
    width: initial;
  }
  .testimonials__title {
    font-size: 36px;
   }
}

@media screen and (width >= 1200px) {

  .testimonials__slider-head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .testimonials__slider .slider__controls {
    margin-bottom: 0;
  }

  .testimonials__title {
    text-align: left;
    font-size: 48px;
    margin-bottom: 0;
    width: initial;
   }

  .testimonials__copy {
    margin-left: 5%;
    flex-grow: 1;
  }

  .testimonials__details {
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: none;
    float: left;
  }
  .details__detail {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #c4cde1;
    flex-grow: 0;
    padding: 10px 0;
    text-align: left;
  }
  .testimonial__text {
    width: 65%;
    margin-left: 10%;
    float: right;
  }
  .testimonial__author {
    text-align: left;
  }
  .news-head {
    margin-bottom: 40px;
  }
  .news-head .slider__controls {
    margin-bottom: 0;
  }
}

@media screen and (width >= 1600px) {

  .testimonials__copy {
    margin-left: 17%;
  }

}
