/* variables */

:root {
  --blue: #4862B1;
  --primary: #1B3070;
  --paragraph: #49598D;
  --secondary: #7683A9;
  --tetriary: #A4ACC6;
  --background: #E5E5E5;
  --green: #8AC249;
  --main-font: 14px;
  --headH: 76px;
}

/* fonts */

@font-face {
    font-family: 'interregular';
    src: url('fonts/inter-regular-webfont.woff2') format('woff2'),
         url('fonts/inter-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

/* main styles */

body {
  font-size: var(--main-font);
  line-height: 1.75;
  color: var(--paragraph);
  font-family: 'interregular', 'Arial', 'sans-serif';
  background-color: var(--background);
  margin: 0;
}

h1, h2, p {
  margin-block-start: 0;
  margin-block-end: 0;
}

/* helpers */


.container {
  margin: 0 auto;
  padding: 0 15px;
}

.container-narrow {
  margin: 0 auto;
  padding: 0 15px;
}

@media screen and (width >= 600px) {
  .container {
    margin: 0 auto;
    padding: 0 28px;
  }
}

@media screen and (width >= 768px) {
  .container {
    padding: 0;
    margin: 0 auto;
    max-width: 748px;
  }
  .container-narrow {
    margin: 0 auto;
    padding: 0 28px;
  }
}

@media screen and (width >= 1200px) {
  .container {
    margin: 0 auto;
    max-width: 1000px;
  }

  .container-narrow {
    margin: 0 auto;
    max-width: 850px;
  }

}

@media screen and (width >= 1600px) {
  .container-narrow {
    margin: 0 auto;
    max-width: 850px;
  }
  .container {
    margin: 0 auto;
    max-width: 1300px;
  }
}

.hidden {
  display: none;
}

/* common */

img {
  width: 100%;
}


.body-fixed {
  overflow: hidden;
}

.contact-btn {
  display: block;
  outline: none;
  border: none;
  background-color: var(--green);
  height: 48px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 48px;
  color: #fff;
  -webkit-appearance: none;
  text-decoration: none;
  text-align: center;
}

.slider__control {
  background: var(--green);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 48px;
  width: 48px;
  color: #fff;
  font-size: 33px;
  outline: none;
  cursor: pointer;
  z-index: 100;
}

/* swiper slider overrrides */

.swiper-pagination {
  position: static !important;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-pagination-bullet-active {
  background: var(--blue) !important;
}

.swiper-pagination-fraction {
  width: auto !important;
}

.other-case__slider .slider__counter{
  color: #fff !important;
}
.other-case__slider .swiper-container {
  border-top: 1px solid rgba(255, 255, 255, .24);
  clear: left;
  margin-top: 50px;
}

@media screen and (width >= 768px) {
  .other-case__slider . {
    float: right;
  }
  .other-case__slider .swiper-container {
    margin-top: 65px;
  }
}

/* fotorama overrides */


.fotorama__arr {
  background: none;
}

.fotorama__arr--prev {
  background: url('../img/icons/arrow-left-round.png') no-repeat center !important;
  border-radius: 50%;
}
.fotorama__arr--next {
  background: url('../img/icons/arrow-right-round.png') no-repeat center !important;
  border-radius: 50%;
}
