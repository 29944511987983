
header {
  position: relative;
  /* z-index: 10000; */
}

.header-filled {
  background: #e5e5e5;
  box-shadow: 2px 0px 21px 0px rgba(0,0,0,0.54);
}



.tiled-bg {
  background: var(--blue) url('../img/tiles/tile-left.png') no-repeat center;
  background-size: contain;
  width: 250px;
  height: 250px;
  position: absolute;
  z-index: 500;
  top: 0;
  left: 0;
}

@media screen and (width >= 1200px) {
  .header-filled .header__wrapper {
    height: calc(var(--headH) - 30px);
  }
  .header-filled .header__logo img {
    width: 170px;
    height: auto;
  }

  .header-filled a {
    font-size: 12px;
  }
}

.header__wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: calc(var(--headH) / 2);
  position: relative;
  z-index: 1000;
}

.header__logo {
  display: flex;
}

.header__logo img {
  height: 100%;
  width: auto;
}

.header__menubar {
  display: flex;
}

.header__menu {
  display: none;
}

.header__menu_item {
  margin-right: 52px;
}

.header__menu_divider {
  margin-right: 48px;
}

.header__menu_item {
  color: var(--primary);
  text-transform: uppercase;
}

.header__menu_item a {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}

.header__menu_relative {
  position: relative;
}

.header__menu_arrow {
  display: inline-block;
  width: 8px;
  height: 6px;
  margin-left: 5px;
  color: var(--primary);
  vertical-align: middle;
  cursor: pointer;
  background: transparent url('../img/icons/down-arrow.svg') no-repeat center;
}

.header__popup-menu {
  background-color: #fff;
  padding: 24px 44px 24px 24px;
  position: absolute;
  right: 0;
  top: 36px;
  width: 180px;
  transition: all 0.5s;
  display: none;
}

.header__popup-menu--career {
  left: 0;
}

.popup-language {
  width: 3px;
}

.header__popup-menu.active {
  display: block;
}

.popup-menu__item {
  margin-bottom: 20px;
  text-transform: capitalize;
}

.popup-menu__item a, .popup-language a {
  font-weight: normal;
}

.popup-menu__item:last-child {
  margin-bottom: 0;
}


.popup-mobile {
  display: none;
  flex-flow: column nowrap;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - var(--headH));
  background: var(--background);
  justify-content: space-around;
  align-items: center;
  padding-top: 98px;
}

.popup-mobile--contacts {
  z-index: 10000;
}

.popup-mobile.visible {
  display: flex;
}

.popup-mobile__item {
  margin-bottom: 8vmin;
  border-bottom: 1px solid #c4cde1;
  width: 90%;
  text-align: center;
  padding-bottom: 16px;
}

@media screen and (orientation:landscape) {
  .popup-mobile__item {
     margin-bottom: 0;
 }
}

.popup-mobile__item:last-child {
  margin-bottom: 0;
}

.popup-mobile__item--lang {
  border-bottom: 1px solid #c4cde1;
  width: 90%;
  text-align: center;
  padding-bottom: 26px;
  margin-bottom: 2vmin;
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
}

.popup-mobile__item a, .popup-mobile__item--lang a {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}

@media screen and (width >= 768px) {
  .header__logo {
    flex-grow: 1;
  }
  .header__logo img {
    margin: 0 auto;
    padding-left: 24px;
    height: 100%;
    width: 180px;
  }
}

@media screen and (width >= 1200px) {
  .header__wrapper {
    height: var(--headH);
  }
  .header__logo {
    flex-grow: 0;
  }
  .header__logo img {
    padding-left: 0;
    width: 230px;
    height: 38px;
  }
  .header__menubar {
    display: none;
  }
  .header__menu {
    display: flex;
  }
  .header__menu_item:last-child {
    margin-right: 0;
  }
}